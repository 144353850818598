<template>
    <div class="notification is-info mt-4 text-center" v-if="!isPlanningActive">
        Achtung: Sie machen gerade eine Eintragung <br>
        außerhalb der dafür vorgesehenen Planningzeit.
    </div>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Objective ändern
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" v-model="editedObjective.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Team:</td>
                <td class="vertical-center">
                    <select class="input-field-big" v-model="editedObjective.team"> 
                        <option v-for="team in teamsTeamLead" :value="team.id">{{ team.name }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="editedObjective.status">
                            <option value="Not started">Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Grün (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="editedObjective.threshold_green" @input="validatePercentage('threshold_green')">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Rot (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="editedObjective.threshold_red" @input="validatePercentage('threshold_red')">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="radio-group my-2 ml-2">
                        <div v-for="period in activeWorkingPeriods" :key="period.id" class="radio-option">
                            <input
                            type="radio"
                            :value="period"
                            :id="`period-${period.value}`"
                            v-model="selectedWorkingPeriod"
                            name="workingPeriod"
                            >
                            <label :for="`period-${period.value}`" class="spaced"> {{ period.name }}</label>
                        </div>
                    </div>
                </td> 
            </tr>
            <!-- ############### MOALs ############### -->
            <tr>
                <td class="vertical-center">Moals:</td>
                <td class="vertical-center pl-3">
                <Multiselect
                    class="max-w-[200px] mt-1 mb-1"
                    :options="moals_fetched"
                    v-model="editedObjective.moals"
                    :multiple="true"
                    :searchable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :max-height="120"
                    :selectLabel="''"
                    :deselectLabel="''"
                    placeholder="Moals auswählen"
                    label="name"
                    track-by="id"
                />
                    <!-- @input="updateMembers" -->
                </td>
            </tr>
            <!-- <tr>
                <td class="vertical-center">Moals:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" multiple v-model="editedObjective.moals">
                            <option v-for="moal in moals_fetched" :value="moal.id">{{ moal.name }}</option>
                        </select>
                    </div>
                </td>
            </tr> -->
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td class="vertical-center">
                    <strong>
                        MOAL Gewichtung:
                    </strong>
                </td>
            </tr>
            <!-- New try -->
            <tr v-for="moal in editedObjective.moals" :key="moal.id">
                <td class="vertical-center">{{ moal.name }}:</td>
                <!-- <td class="vertical-center">{{ moal.id }}:</td> -->
                <!-- <td class="vertical-center">{{ moalWeights }}:</td> -->
                <!-- <td class="vertical-center">{{ moalWeights[moal.id] }}:</td> -->
                <!-- <td class="vertical-center">{{ Object.toRaw(moalWeights)[moal.id] || 1 }}</td> -->

                <td class="vertical-center">
                    <div>
                        <input 
                            type="number" 
                            class="input-field-big" 
                            v-model="moal.weight_objective" 
                        />
                    </div>
                </td>
            </tr>
            <!-- Old -->
            <!-- <tr v-for="(moalId, index) in editedObjective.moals" :key="index">
                <td class="vertical-center">{{ findMoalName(moalId) }}:</td>
                <td class="vertical-center">
                    <div>
                        <input 
                            type="number" 
                            class="input-field-big" 
                            v-model="moalWeights[moalId]" 
                        />
                    </div>
                </td>
            </tr> -->
            <tr v-if="keyresults.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="keyresults.length > 0">
                <td class="vertical-center">
                    <strong>
                        Gewichte Keyresults:
                    </strong>
                </td>
            </tr>
            <tr v-for="keyresult in editedKeyresults" :key="keyresult.id">
                <td class="vertical-center">{{ keyresult.name }}:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="keyresult.weight_multiplier">
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="deleteObjective">Löschen</button>
    </div>
    <!-- <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div> -->
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex'
import { reactive, toRaw } from 'vue'

export default {
    name: 'Modal_ChangeObjective',
    emits: ['objectiveChanged', 'keyresultChanged'], 
    components: { Multiselect },
    props: {
        objective: Object,
        keyresults: Array, 
        activeWorkingPeriods: Array,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            // EditedObjective is filled with moal objects, 
            // and the moal objects are filled with weight_objective.
            // This is then unpacked for post requests to server.
            // TODO find a cleaner way to handle the data. Idea: Direct usage of weight dictionary in template
            editedObjective: { 
                ...this.objective,
                moals: [], 
            },
            editedKeyresults: JSON.parse(JSON.stringify(this.keyresults)),
            errors: [],
            moals_fetched: [],
            // moalWeights: this.objective.moal_weights,  // Initialize from objective
            // moalWeights: reactive(this.objective.moal_weights || {}),

            // moalWeights: typeof this.objective.moal_weights === 'string' 
            //     ? JSON.parse(this.objective.moal_weights)
            //     : this.objective.moal_weights || {},
            customSelected: false,
            showHelpText: false,
            selectedWorkingPeriod: '',
        }
    },
    mounted() {
        // console.log('this.objective.moal_weights:', this.objective.moal_weights);
        // console.log('Type of moal_weights:', typeof this.objective.moal_weights);
        // console.log('this.moalWeights:', this.moalWeights);
        // console.log('moalWeights:', this.objective.moal_weights)
        this.fetchTeams();
        // console.log('after fetchTeams')
        this.getMOALsFillEditedObjective();
        // console.log('after getMOALsFillEditedObjective')
        this.preselectWorkingPeriod();
        // console.log('after preselectWorkingPeriod')
        // this.initializeMoalWeights();
        // console.log('Modal_ChangeObjective Objective:', this.objective)
    },
    computed: {
        ...mapState([
            'teamsTeamLead',
        ])
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.editedObjective.name === '') {
                this.errors.push('The name field is missing!')
            }
            
            if (this.editedObjective.team === '') {
                this.errors.push('The team field is missing!')
            }
            if (this.editedObjective.status === '') {
                this.errors.push('The status field is missing!')
            }

            if (this.editedObjective.threshold_red >= this.editedObjective.threshold_green) {
                this.errors.push('Der Grenzwert zu Rot muss niedriger als der Grenzwert zu grün sein')
            }
            for (let keyresult of this.editedKeyresults) {
                if (typeof keyresult === 'object' && keyresult !== null) {
                    const weight = parseFloat(keyresult.weight_multiplier);
                    if (isNaN(weight) || weight === '') {
                        keyresult.weight_multiplier = 1;
                    }
                }
            }
            if (!this.errors.length) {
                this.progress = parseInt(this.progress);
                if (this.unit === 'custom') {
                    this.unit = this.customInput
                };
                this.changeObjective();
                this.changeKeyresults();
                this.unit = '%';
            }
        },
        async preselectWorkingPeriod() {
            if (!this.objective.is_active) {
                this.selectedWorkingPeriod = this.activeWorkingPeriods[0];
            }
            const workingPeriod = this.activeWorkingPeriods.find(period => period.id === this.objective.working_period);
            this.selectedWorkingPeriod = workingPeriod;
        },
        async changeObjective() {
            this.$store.commit('setIsLoading', true)

            const moalIds = this.editedObjective.moals.map(moal => moal.id);

            await axios
                .patch(`/api/v1/objectives/${this.objective.id}/`, 
                {
                name: this.editedObjective.name,
                team: this.editedObjective.team, 
                owner: this.editedObjective.owner,
                status: this.editedObjective.status,
                // moals: this.editedObjective.moals,
                moals: moalIds,
                moal_weights: this.prepareMoalsForSubmission(), // Changed from multiplier_objective_moal
                // multiplier_objective_moal: this.prepareMoalsForSubmission(),
                working_period: this.selectedWorkingPeriod.id,
                threshold_green: this.editedObjective.threshold_green,
                threshold_red: this.editedObjective.threshold_red,
                },
                )
                .then(response => {
                    this.$emit('objectiveChanged');
                })
                .catch(error => {
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        },
        async changeKeyresults() {
            this.$store.commit('setIsLoading', true)
            for (let keyresult of this.editedKeyresults) {
                await axios
                    .put(`/api/v1/keyresults/${keyresult.id}/`, 
                    {
                    weight_multiplier: keyresult.weight_multiplier,
                    },
                    )
                    .then(response => {
                    this.$emit('keyresultChanged');
                    })
                    .catch(error => {
                    console.error('Error:', error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        async deleteObjective() {
            this.$store.commit('setIsLoading', true)
            await axios
                .delete(`/api/v1/objectives/${this.objective.id}/`)
                .then(response => {
                this.$emit('objectiveChanged');
                })
                .catch(error => {
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        },
        fetchTeams() {
            this.$store.dispatch('fetchTeamsTeamlead')
                .then(() => {
                });
        },
        async getMOALsFillEditedObjective() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get(`/api/v1/moals/`);
                this.moals_fetched = response.data;

                this.moals_fetched = response.data.map(moal => ({
                    ...moal,
                    weight_objective: this.objective.moal_weights?.[moal.id] || 1
                }));

                if (this.objective.moals && this.moals_fetched) {
                    this.editedObjective.moals = this.objective.moals
                        .map(id => this.moals_fetched.find(moal => moal.id === id))
                        .filter(moal => moal); // Remove any undefined values
                }

                // console.log('MOALs:', this.moals_fetched)
                console.log('EditedObjective filled with MOALs:', this.editedObjective)
                this.initializeMoalWeights()
            } catch (error) {
                console.error('Error fetching moals:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        findMoalName(id) {
            const moal = this.moals_fetched.find(moal => moal.id === id);
            return moal ? moal.name : 'Unknown';
        },
        // initializeMoalWeights() {
        //     if (!this.editedObjective.moals) return;

        //     const multipliers = this.editedObjective.multiplier_objective_moals || [];

        //     console.log('moals:', this.editedObjective.moals)
        //     console.log('multipliers:', multipliers)

        //     this.editedObjective.moals.forEach(moalId => {
        //         const multiplier = multipliers.find(mult => mult.moal === moalId);
        //         this.moalWeights[moalId] = multiplier ? multiplier.weight : 1;
        //     });
        //     console.log('Initialized weights:', this.moalWeights);
        // },
        initializeMoalWeights() {
            if (!this.editedObjective.moals) return;

            // console.log('moals:', this.editedObjective.moals);
            // console.log('moal_weights:', this.objective.moal_weights);
            // console.log('objective Test', this.objective);
            // const weights = toRaw(this.moalWeights);
            // console.log('weights:', weights);
            // console.log('moalWeights:', this.moalWeights);
            // console.log('this.objective.moal_weights:', this.objective.moal_weights);

            this.editedObjective.moals = this.editedObjective.moals.map(moal => ({
                ...moal,
                weight_objective: this.objective.moal_weights?.[moal.id] || 1
            }));
            console.log('Initialized objective with moals and weights:', this.editedObjective);

            // this.editedObjective.moals.forEach(moal => {
            //     if (!weights[moal.id]) {
            //         weights[moal.id] = 1; // Default weight if not found
            //     }
            // });
            // this.moalWeights = reactive(weights);

            // console.log('Initialized weights:', this.moalWeights);

        },
        // prepareMoalsForSubmission() {
        //     const connections = [];
        //     for (const moalId in this.moalWeights) {
        //         connections.push({
        //             moal: moalId,
        //             weight: this.moalWeights[moalId]
        //         });
        //     }
        //     return connections;
        // },
        prepareMoalsForSubmission() {
            // Convert array of moals with weight_objective into dictionary format
            const weights = {};
            this.editedObjective.moals.forEach(moal => {
                weights[moal.id] = moal.weight_objective;
            });
            return weights;
        },
        handleCustomUnit() {
            this.validateProgress()
            if (this.unit === ' ') {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
        validatePercentage(field) {
            if (this[field] < 0) {
                this.field = 0;
            } else if (this[field] > 100) {
                this[field] = 100;
            }
        },
    },
    // watch: {
    //     'editedObjective.moals': {
    //         handler(newVal, oldVal) {
    //             // Initialize weight for newly added moals
    //             newVal.forEach(id => {
    //                 if (!this.moalWeights[id]) {
    //                     this.moalWeights[id] = 1; // Default weight of 1
    //                 }
    //             });
    //             // Remove weight for deselected moals
    //             for (const id in this.moalWeights) {
    //                 if (!newVal.includes(Number(id))) {
    //                     delete this.moalWeights[id];
    //                 }
    //             }
    //         },
    //         deep: true
    //     }
    // }
}
</script>
