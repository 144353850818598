<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Team bearbeiten
    </strong>
    <table>
        <tbody>
            <!-- ################################ Members ################################ -->
            <tr>
                <td class="vertical-center">Teammitglieder:</td>
                <td class="vertical-center pl-3">
                    <!-- <select multiple class="input-field-big" v-model="editedTeam.members"> 
                        <option v-for="user in users" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </select> -->
                    <multiselect
                        class="max-w-[200px] mt-1 mb-1"
                        :options="users"
                        v-model="selectedUsers"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :max-height="120"
                        :selectLabel="''"
                        :deselectLabel="''"
                        placeholder="Mitglieder auswählen"
                        label="full_name"
                        track-by="id"
                        @input="updateMembers"
                    />
                </td>
            </tr>
            <!-- ################################ Checkin day ################################ -->
            <tr>
                <td class="vertical-center">Wochentag Check-in:</td>
                <td>
                    <select class="input-field-big" v-model="editedTeam.checkin_day">
                        <option value="1">Montag</option>
                        <option value="2">Dienstag</option>
                        <option value="3">Mittwoch</option>
                        <option value="4">Donnerstag</option>
                        <option value="5">Freitag</option>
                        <option value="6">Samstag</option>
                        <option value="7">Sonntag</option>
                    </select>
                </td>
            </tr>

        </tbody>
    </table>
    <!-- ####################### submit form ###################### -->
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>

</template>


<script>

import axios from 'axios'


export default {
    name: 'Modal_ChangeTeamCheckin',
    emits: ['teamChanged', 'teamDeleted'],
    props: {
        team: {type: Object, required: true},
        permissions: {type: Array, required: true},
        users: {type: Array, required: true},
        users_teamlead: {type: Array, required: true},
    },
    data() {
        return {
            editedTeam: { ...this.team },
            errors: [],
            teamlead: null,
            showHelpText: false,
        }
    },
    methods: {
        submitForm() {
            console.log('Submitting form second:', this.name, this.members, this.teamlead);
            this.errors = []

            if (this.checkin_day === '') {
                this.errors.push('The progress unit is missing!')
            }

            if (!this.errors.length) {
                this.saveChanges();
            }
        },
        async saveChanges() {
            try {
                const response = await axios.put(`/api/v1/teams/${this.editedTeam.id}/`, this.editedTeam);
                // console.log('Team updated:', response.data);
                this.$emit('teamChanged', response.data);
            } catch (error) {
                console.error('Error updating team:', error);
            }
        },
 
    },
}
</script>