<template>
    <tr class="tableRow">
        <td class="verticalCenterTD text-left">
            <div>{{this.user.email}}</div>
        </td>
        <td class="verticalCenterTD text-center">
            <div>{{this.user.first_name}}</div>
        </td>
        <td class="verticalCenterTD text-center">
            <div>{{this.user.last_name}}</div>
        </td>
        <td class="verticalCenterTD text-center">
            <div> {{this.date}}</div>
        </td>
        <td class="verticalCenterTD text-center">
            <div>{{this.licensetype_user}}</div>
        </td>
        <td class="verticalCenterTD text-center">
            <div>{{ this.user.is_active ? 'Ja' : 'Nein' }}</div>
        </td>
        <!-- <td class="verticalCenterTD text-center">
            <div v-if="this.licenses_teamlead > 0"> {{ this.licenses_teamlead }}</div>
            <div v-else>Nein</div>
        </td> -->
        <td class="verticalCenterTD text-center">
            <div v-if="this.licenses_standard > 0">{{ this.licenses_standard }}</div>
            <div v-else>Nein</div>
        </td>    

    </tr>
</template>

<script>
export default {
    name: 'Component_Licenses_User',
    emits: ['requestPermissions'],
    props: {
        user: {
            type: Object,
            required: true
        },
        // licenses_teamlead: Number,
        licenses_standard: Number,
        licensetype_user: String,
    },
    // mounted() {
    //     console.log("this.user", this.user)
    // },
    computed: {
        date() {
            return new Date(this.user.date_joined).toLocaleDateString('de-DE')
        }
    }
}
</script>