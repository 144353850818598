<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Neues Team hinzufügen
    </strong>
    <table>
        <tbody>
            <tr>
                <!-- ################################ Name ################################ -->
                <!-- TODO give this some space -->
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" placeholder="New Team" v-model="name"/>
                    </div>
                </td>
            </tr>

                <!-- ################################ Team lead ############################ -->
            <tr>

                <td class="vertical-center">Teamlead:</td>
                <td class="vertical-center">
                    <!-- <div class="flex-center"> -->
                    <select class="input-field-big dropdown" v-model="teamlead">
                        <!-- <option disabled value="">Bitte Teamlead auswählen</option> -->
                        <option v-for="user in users_teamlead" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </select>
                    <!-- </div> -->
                </td>
            </tr>

                <!-- ################################ Members ################################ -->
            <tr>
                <td class="vertical-center">Teammitglieder:</td>
                <td class="vertical-center pl-3">
                    <!-- <select multiple class="input-field-big" v-model="members"> 
                        <option v-for="user in users" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </select> -->
                    <multiselect
                        class="max-w-[200px] mt-1 mb-1"
                        :options="users"
                        v-model="selectedUsers"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :max-height="120"
                        :selectLabel="''"
                        :deselectLabel="''"
                        placeholder="Mitglieder auswählen"
                        label="full_name"
                        track-by="id"
                        @input="updateMembers"
                    />
                    <!-- class="w-[20px] mt-10 mb-10 bg-green" -->

                    <!-- @update="updateMembers"
                    :selected="members" -->
                    
                    <!-- v-model="members" -->
                        <!-- <template slot="selection" slot-scope="{ values, isOpen }">
                            <span v-if="values.length && !isOpen" class="multiselect__single">
                            {{ values.length }} options selected
                            </span>
                        </template> -->
                </td>
            </tr>

            <!-- ################################ Checkin day ################################ -->
            <tr>
                <td class="vertical-center">Wochentag Check-in:</td>
                <td class="vertical-center">
                    <select class="input-field-big dropdown" v-model="CheckinDay">
                        <option value="0">Undefiniert</option>
                        <option value="1">Montag</option>
                        <option value="2">Dienstag</option>
                        <option value="3">Mittwoch</option>
                        <option value="4">Donnerstag</option>
                        <option value="5">Freitag</option>
                        <option value="6">Samstag</option>
                        <option value="7">Sonntag</option>
                    </select>
                </td>
            </tr>

        </tbody>
    </table>
    <!-- ####################### submit form ###################### -->
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Team hinzufügen</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>

</template>


<script>

import axios from 'axios'
// import { search } from 'core-js/fn/symbol';
import Multiselect from 'vue-multiselect';



export default {
    name: 'Modal_AddTeam',
    components: { Multiselect },

    emits: ['teamAdded'],
    props: {
        // team: Object,
        permissions: {
            type: Array,
            required: true
        },
        users: {
            type: Array,
            required: true
        },
        users_teamlead: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            name: 'Default Team',
            errors: [],
            members: [],
            teamlead: [],
            CheckinDay: 0,
            showHelpText: false,
        }
    },
    computed: {
        // Combine first and last name for display
        // usersWithFullName: {
        //     get() {
        //         return this.users.map(user => ({
        //             ...user,
        //             full_name: `${user.first_name} ${user.last_name}`,
        //         }));
        //     }
        // },

        //     return this.users.map(user => ({
        //         ...user,
        //         fullName: `${user.first_name} ${user.last_name}`,
        //     }));
        // },
        selectedUsers: {
            get() {
                return this.users.filter(user => this.members.includes(user.id));
            },
            set(selectedUsers) {
                this.members = selectedUsers.map(user => user.id);
            }
        },
    },
    mounted() {
        // console.log('users:', this.users); 
        // console.log('users_teamlead:', this.users_teamlead);
        // if (this.users_teamlead) {
        //     this.teamlead = this.users_teamlead[0].id
        // }
    },
    methods: {
        submitForm() {
            // console.log('Submitting form second: name', this.name, '\n members', this.members, '\n teamlead', this.teamlead, '\n checkin_day', this.CheckinDay);
            this.errors = []

            if (this.name === '') {
                this.errors.push('Der Teamname fehlt')
            }
            if (this.members === '') {
                this.errors.push('Die Teammitglieder fehlen')
            }
            if (this.teamlead === '') {
                this.errors.push('Das Teamlead fehlt')
            }
            if (this.CheckinDay === '') {
                this.errors.push('Der Wochentag für den Check-in fehlt')
            }

            if (!this.errors.length) {
                this.addTeam();
            }
        },
        async addTeam() {
            this.$store.commit('setIsLoading', true)
            // console.log('Adding team: name', this.name, 'members', this.members, 'teamlead', this.teamlead, 'checkin_day', this.CheckinDay);
            await axios
                .post('/api/v1/teams/', 
                {
                name: this.name,
                teamlead: this.teamlead,
                members: this.members, 
                checkin_day: this.CheckinDay
                },
                )
                .then(response => {
                // console.log('Response:', response.data);
                this.$emit('teamAdded');
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
        // updateMembers(value) {
        //     this.members = value.map(user => user.id);
        // },
    },
}
</script>

<style>
@import 'vue-multiselect/dist/vue-multiselect.min.css';



/* .multiselect__option {
    @apply px-3 py-2
    text-gray-900 dark:text-gray-100
    bg-teal-200
    hover:bg-gray-100 dark:hover:bg-gray-700;
} */
/* Placeholder text */


</style>


<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> 
<style>
/* Main input container */
.multiselect__tags {
    border: 0.5px solid !important;
    border-color: rgb(229 231 235) !important;
    height: 2.5rem;
    width: 15rem;
    background: transparent;
    font-family: WorkSans-light !important;
    font-size: 14px !important;
    padding: 7px !important;
    margin: 4px
}
/* Selected tag styling */
.multiselect__tag {
    border: 3px solid rgba(60, 60, 60, 0.26) !important;
    margin-bottom: 0px !important;
    margin-right: 5px !important;
    width: 8rem;
    background-color: rgba(60, 60, 60, 0.26) !important;
    /* min-height: 20px !important;
    min-width: 25px !important; */
}

/* Dropdown container */
.multiselect__content-wrapper {
    @apply border border-green-200 
    rounded-md
    bg-purple-300 dark:bg-gray-800
    shadow-lg;
}

/* Individual option in dropdown */
.multiselect__option {
    @apply px-3 py-2
    text-gray-900 dark:text-gray-100
    hover:bg-gray-100 dark:hover:bg-gray-700;
}

/* Selected option in dropdown */
.multiselect__option--selected {
    @apply bg-blue-50 dark:bg-blue-900
    text-blue-600 dark:text-blue-200;
}

/* Remove button (x) on tags */
.multiselect__tag-icon {
    @apply hover:bg-red-500
    rounded-full
    transition-colors;
}
/* Placeholder text */
.multiselect__placeholder {
    display: inline-block !important;
    margin-bottom: 20px !important;
    padding-top: 50px !important;
    width: 9rem;
}  

/* Search input */
.multiselect__input {
    @apply bg-transparent
    text-gray-900 dark:text-gray-100
    placeholder-gray-400;
}

/* Selected items count */
/* .multiselect__single {
    @apply text-gray-600 dark:text-gray-300
    margin-bottom-0;
} */

</style> -->
