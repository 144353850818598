<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Lizenzen vergeben
    </strong>
    <table>
        <tbody>
            <!-- ################################ Type ################################ -->
            <tr>
                <td class="vertical-center">Typ:</td>
                <td class="vertical-center">
                    <div v-if="licenses.admin.all.length">
                        <select class="input-field-big" v-model="role">
                            <option value="admin">Admin</option>
                            <option value="teamlead">Teamlead</option>
                            <option value="standard">Standard</option>
                        </select>
                    </div>
                    <div v-else>
                        Standard
                    </div>
                </td>
            </tr>

            <!-- ################################ Sublicenses ############################ -->
            <tr v-if="role == 'teamlead'">
                <td class="vertical-center">Vergebbare Lizenzen:</td>
                <td class="vertical-center">
                    <input type="number" class="input-field-big" placeholder="" v-model="number_standard_redistribute">
                </td>
            </tr>

            <!-- ################################ Email ############################ -->
            <tr>
                <td class="vertical-center">E-Mail:</td>
                <td class="vertical-center">
                    <!-- <div class="flex-center"> -->
                    <input type="email" class="input-field-big" placeholder="E-Mail Neuer Nutzer" v-model="email">
                    <!-- </div> -->
                </td>
            </tr>
            <tr>
                <td class="vertical-center">E-Mail wiederholen:</td>
                <td class="vertical-center">
                    <!-- <div class="flex-center"> -->
                    <input type="email" class="input-field-big" placeholder="E-Mail wiederholen" v-model="email_retype">
                    <!-- </div> -->
                </td>
            </tr>
            <!-- ############################### Name ########################### -->
            <tr>
                <td class="vertical-center">Vorname:</td>
                <td class="vertical-center">
                    <input type="text" class="input-field-big" placeholder="Vorname" v-model="first_name">
                </td>  
            </tr>
            <tr>
                <td class="vertical-center">Nachname:</td>
                <td class="vertical-center">
                    <input type="text" class="input-field-big" placeholder="Nachname" v-model="last_name">
                </td>  
            </tr>
            <tr>
                <td class="vertical-center">Persönliche Nachricht:</td>
                <td class="vertical-center">
                    <textarea type="text" class="input-field-textarea" placeholder="Persönliche Nachricht"  v-model="message"/>
                </td>  
            </tr>
        </tbody>
    </table>
    <!-- ####################### submit form ###################### -->
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Lizenz vergeben</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_Company_AssignLicenses',
    emits: ['licenseAssigned'],
    props: {
        licenses: {
            default: []
        }
    },
    data() {
        return {
            role: 'standard',
            number_standard_redistribute: 0,
            assigned_license_main: '',
            assigned_licenses_redistribute: [],
            email: '',
            email_retype: '',
            first_name: '',
            last_name: '',
            message: '',
            errors: [],
        }
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.email === '') {
                this.errors.push('The email field is missing!')
            }
            if (this.email_retype === '') {
                this.errors.push('The email retype field is missing!')
            }
            if (this.email !== this.email_retype) {
                this.errors.push('The email fields do not match!')
            }
            if (this.role == 'admin') {
                if (this.licenses.admin.free.length < 1) {
                    this.errors.push('You do not have any free admin licenses to assign!')
                    return
                }
            }
            if (this.role == 'teamlead') {
                if (this.number_standard_redistribute > this.licenses.standard.free.length) {
                    this.errors.push('You do not have enough free standard licenses to redistribute!')
                    return
                }
                if (this.licenses.teamlead.free.length < 1) {
                    this.errors.push('You do not have any free teamlead licenses to assign!')
                    return
                }
            }
            if (this.role == 'standard') {
                if (this.licenses.standard.free.length < 1) {
                    this.errors.push('You do not have any free standard licenses to assign!')
                    return
                }
            }
            if (!this.errors.length) {
                this.assignLicenses();
            }
        },
        async assignLicenses() {
            // console.log('assignLicenses');
            if (this.role == 'admin') {
                this.assigned_license_main = this.licenses.admin.free[0].license_number;
            } else if (this.role == 'teamlead') {
                this.assigned_license_main = this.licenses.teamlead.free[0].license_number;
                this.assigned_licenses_redistribute = this.licenses.standard.free.slice(0, this.number_standard_redistribute).map(license => license.license_number);            
            } else if (this.role == 'standard') {
                this.assigned_license_main = this.licenses.standard.free[0].license_number;
            }
            console.log('assigned_license_main:', this.assigned_license_main, 'assigned_licenses_redistribute:', this.assigned_licenses_redistribute);
            await axios
                .post('/api/v1/assign-licenses/', {
                    license_number: this.assigned_license_main,
                    license_numbers_redistribute: this.assigned_licenses_redistribute,
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    message: this.message,
                }) 
                .then(response => {
                    console.log('Response:', response.data);
                    console.log('licenseAssigned');
                    this.$emit('licenseAssigned');
                }) 
                .catch(error => {
                    console.error('Error:', error)
                })


            // console.log('assignLicenses');
        } 
    }
}
</script>