<template>
    <!-- <td v-if="permissions.includes('product.add_keyresult')" class="okrFirstColumn"></td> -->

    <!-- ##################################### Name ################################## -->
    <td class=" relative vertical-center-table border-b-0 bg-cell-keyresult ">
        <div class="group inline-block relative">
            <button 
                @click="isDetailModalOpen = true" 
                class=" actionButtonNoBg ml-12 align-middle items-center text-left text-gray-900 dark:text-gray-100"
                >
                {{ keyresult.name }}
                <!-- <span class="tooltipTop">Test inside</span> -->
                

            </button>
            <Component_Tooltips_Keyresult
                v-if="keyresult.details !== null && keyresult.details.length > 0"
                :details="keyresult.details"    
            />
            <!-- <span class="tooltipTop group-hover:scale-100">
                Test outside
            </span> -->
        </div>
        <Teleport to="#modal">
            <Transition name="modal">
                <div v-if="isDetailModalOpen" class="modal-bg" @click.self="isDetailModalOpen = false">
                    <div class="modal">
                        <Component_Modal_InfoButton :infoText="infoTextDetailKeyresult" />
                        <button @click="isDetailModalOpen = false" class="close-btn">x</button>
                        <Modal_OKR_Keyresult_Detail
                        v-if="permissions.includes(`olp_view_keyresult_${this.keyresult.id}`)"
                        v-bind:keyresult="keyresult"
                        v-bind:permissions="permissions"
                        :threshold_green="this.threshold_green"
                        :threshold_red="this.threshold_red"
                        />
                    </div>
                </div>
            </Transition>
        </Teleport>
    </td>
    
    <!-- ##################################### Team ################################## -->
    <!-- <td></td> -->

    <!-- ##################################### Owner ############################ -->
    <td class="vertical-center-table bg-cell-keyresult border-b-0 table-cell text-left bg-cell-keyresult">
        {{ keyresult.owner_name }}
    </td>

    <!-- #################################  Progress  ##################################### -->
    <td class="vertical-center-table bg-cell-keyresult border-b-0 table-cell text-center relative">
        <div class="flex-row-wrapper">
            <td class="border-0 align-middle p-1 text-right whitespace-nowrap">
                <span class="inline-block align-middle m-1 bg-cell-keyresult">{{ keyresult.start }}</span> <!-- Example label -->
                <!-- <div class="progress-bar-container bg-gray-200 absolute left-[50%] translate-x-[-50%]"> -->
            </td>
            <td class="border-0 p-0 w-full align-middle">
                <div class="w-full h-4 rounded-md overflow-hidden flex relative justify-center align-middle align-center bg-gray-200">

                <!-- <div class="progress-bar-filled" :style="{ width: keyresult.progress_percentage + '%', backgroundColor: progressBarFrontColor }"></div> -->
                <!-- <div class="progress-bar-filled" :class="[progressBarWidthClass, progressBarFrontColorClass]"></div> -->
                <div class="progress-bar-filled" :class="[progressBarWidthClass, progressBarFrontColorClass]"></div>
            
                <span class="progress-value">{{ keyresult.value_current }}</span> 
                </div>
            </td>
            <td class="border-0 p-0 text-left whitespace-nowrap">
                <span class="inline-block align-middle m-1 text-gray-900 dark:text-gray-100">{{ keyresult.goal }}</span>            
                <span class="inline-block align-middle m-0 text-gray-900 dark:text-gray-100"> {{ unit_calculated }}</span>
            </td>
        </div>
    </td>

    <!--############################# status ##########################################-->
    <!-- <td class="vertical-center-table table-cell text-center">{{ keyresult.status }}</td> -->


    <!-- ########################### Zeitraum ####################### -->
    <td class="table-cell bg-cell-keyresult border-b-0 text-left"></td>

    <!-- ########################### Actions ########################### -->
    <!-- <td v-if="permissions.includes(`olp_change_objective_${keyresult.id}`)"> -->
    <!-- <td class="vertical-center-table table-cell flex text-center"> -->
        <!-- TODO change so that you can also add Keyresult from Archive to specific Objectives -->
    <td class="text-center verticalCenterTD bg-cell-keyresult border-b-0 w-[30%] text-[0.9rem]">

                <div class="" v-if="permissions.includes(`olp_change_keyresult_all_${this.keyresult.id}`)">
                    <!-- <div v-if="permission.includes"(`olp_`)> -->
                    <button class="actionButtonSmall"  @click="isModalFullEditOpen = true" >Ändern</button>
                    <Teleport to="#modal">
                        <Transition name="modal">
                            <div v-if="isModalFullEditOpen" class="modal-bg" @click.self="isModalFullEditOpen = false">
                                <div class="modal">
                                    <Component_Modal_InfoButton :infoText="infoTextAddKeyresult" />
                                    <button @click="isModalFullEditOpen = false" class="close-btn">x</button>
                                    <Modal_ChangeKeyresult
                                    v-if="permissions.includes(`olp_change_keyresult_all_${this.keyresult.id}`)"
                                    v-bind:keyresult="keyresult"
                                    v-bind:team_users="team_users"
                                    v-bind:isPlanningActive="isPlanningActive"
                                    v-on:keyresultChanged="handleKeyresultChanged"
                                    />
                                </div>
                            </div>
                        </Transition>
                    </Teleport>
                    <!-- <button v-show="permissions.includes(`olp_change_keyresult_partly_${this.keyresult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyresult.id}`)" v-else @click="saveChanges()" class="button is-success">Speichern</button> -->
                </div>
                <div class="" v-if="permissions.includes(`olp_change_keyresult_partly_${this.keyresult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyresult.id}`)">
                    <button class="actionButtonSmall" @click="isModalCheckinOpen = true">Check-in</button>
                    <Teleport to="#modal">
                        <Transition name="modal">
                            <div v-if="isModalCheckinOpen" class="modal-bg" @click.self="isModalCheckinOpen = false">
                                <div class="modal">
                                    <Component_Modal_InfoButton :infoText="infoTextAddCheckin" />
                                    <button @click="isModalCheckinOpen = false" class="close-btn">x</button>
                                    <Modal_ChangeKeyresultCheckin
                                    v-if="permissions.includes(`olp_change_keyresult_partly_${this.keyresult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyresult.id}`)"
                                    v-bind:keyresult="keyresult"
                                    v-bind:lastCheckin="lastCheckin"
                                    v-on:keyresultChanged="handleKeyresultChanged"
                                    />
                                </div>
                            </div>
                        </Transition>
                    </Teleport>
                    <!-- <div v-else>
                        <div>
                            &nbsp;
                        </div>
                        <button  class="button is-ghost"></button> 
                    </div> -->


                </div>
    </td>
</template>

<script>
import axios from 'axios'
import Modal_ChangeKeyresult from '@/components/Modals/Modal_ChangeKeyresult.vue'
import Modal_ChangeKeyresultCheckin from '@/components/Modals/Modal_AddCheckin.vue'
import Modal_OKR_Keyresult_Detail from '@/components/Modals/Modal_OKR_Keyresult_Detail.vue'
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton.vue'
import Component_Tooltips_Keyresult from '@/components/tooltips/Component_Tooltips_Keyresult.vue'

import { progressBarBackgroundColor } from '@/utils/utils.js';
import { progressBarColor } from '@/utils/utils.js';
import { infoTextAddKeyresult, infoTextAddCheckin, infoTextDetailKeyresult } from '@/utils/infotexts.js';

export default {
    name: 'Component_OKR_Keyresult',
    emits: ['keyresultDeleted', 'keyresultUpdated'],
    components: {
        Modal_ChangeKeyresult,
        Modal_ChangeKeyresultCheckin,
        Modal_OKR_Keyresult_Detail,
        Component_Modal_InfoButton,
        Component_Tooltips_Keyresult,
    },
    props: {
        keyresult: Object,
        teamName: String,
        team_users: Array,
        isArchive: Boolean,
        threshold_green: Number,
        threshold_red: Number,
        isPlanningActive: Boolean,

    },
    data() {
        return {
            added_team_member: null,
            users: [],
            editedKeyresult: { ...this.keyresult },
            lastCheckin: this.getLastCheckin(),
            // username: null,
            customInput: '',
            customSelected: false,
            infoTextAddKeyresult: infoTextAddKeyresult,
            infoTextAddCheckin:  infoTextAddCheckin,
            infoTextDetailKeyresult: infoTextDetailKeyresult,
            isModalFullEditOpen: false,
            isModalCheckinOpen: false,
            isDetailModalOpen: false,
        }
    },
    computed: {
        progressBarFrontColorClass() {
            // console.log('this.editedKeyresult.progress:', this.editedKeyresult.progress);
            // console.log('this.keyresultprogress:', this.keyresult.progress);
            // return 'bg-green-500';
            return progressBarColor(this.keyresult.progress, this.threshold_green, this.threshold_red);
        },
        progressBarBackgroundColor() {
            return progressBarBackgroundColor(this.keyresult.progress, this.threshold_green, this.threshold_red);
        },
        progressBarWidthClass() {
            const percentage = Math.round(this.keyresult.progress_percentage);
            // console.log('percentage:', this.percentage);
            // console.log('percentage:', `w-[${percentage}%]`);
            // return `w-[${percentage}%]`;
            return `w-${Math.min(Math.max(Math.round(percentage / 5) * 5, 0), 100)}pc`;

            // return 'w-[10%]';
        },
        unit_calculated() {
            if (this.keyresult.unit === 'custom') {
                return this.keyresult.custom_unit;
            } else {
                return this.keyresult.unit;
            }
        },
        permissions() {
            return this.$store.state.permissions;
        },
        // width: .keyresult.progress_percentage + '%',
    },

    methods: {
        // async getUsername(userId) {
        //     try {
        //         const response = await axios.get(`/api/v1/users/${userId}/`);
        //         // this.username = response.data.username;
        //         return this.username;
        //     } catch (error) {
        //         console.error('Error getting username:', error);
        //     }
        // },
        async getLastCheckin() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get(`/api/v1/checkins/?keyresult_id=${this.keyresult.id}`);
                const checkins = response.data;
                // save the latest checkin by filtering by date
                // console.log('Checkins:', checkins);
                // this.lastCheckin = checkins.reduce((prev, current) => (prev.date > current.date) ? prev : current);

                const now = new Date();
                this.lastCheckin = checkins
                .filter(checkin => new Date(checkin.date) <= now)
                .sort((a, b) => new Date(b.date) - new Date(a.date))[0];                
                // return this.lastCheckin;
                // console.log('Last checkin:', this.lastCheckin); 
                // console.log('Checkins:', this.checkins);
  
            } catch (error) {
                console.error('Error fetching last checkin:', error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }
        },
        handleKeyresultChanged() {
            this.isModalFullEditOpen = false;
            this.isModalCheckinOpen = false;
            this.$emit('keyresultUpdated');
        }
    },
}
</script>


<style scoped>


.objective-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.info-column {
  flex: 1;
  margin-right: 10px;
}

.input-field {
  width: 100%;
  height: 30px;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editable-field {
  display: inline-block;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
}


.key-result-row {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Correctly vertically centers the content */
}


.verticalCenterScoped {
  vertical-align: middle;
  /* text-align: center; */
} 

.nested-table {
  width: 100%;
  border-collapse: collapse; /* Optional: to remove gaps between cells */
}


.nested-table td {
  padding: 8px; /* Optional: to add padding inside cells */
  text-align: right; /* Align content to the right */
}

/* .w-0pc { width: 0%; }
.w-5pc { width: 5%; }
.w-10pc { width: 10%; }
.w-15pc { width: 15%; }
.w-20pc { width: 20%; }
.w-25pc { width: 25%; }
.w-30pc { width: 30%; }
.w-35pc { width: 35%; }
.w-40pc { width: 40%; }
.w-45pc { width: 45%; }
.w-50pc { width: 50%; }
.w-55pc { width: 55%; }
.w-60pc { width: 60%; }
.w-65pc { width: 65%; }
.w-70pc { width: 70%; }
.w-75pc { width: 75%; }
.w-80pc { width: 80%; }
.w-85pc { width: 85%; }
.w-90pc { width: 90%; }
.w-95pc { width: 95%; }
.w-100pc { width: 100%; } */

</style>
